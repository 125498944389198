.new_home-small-banner {
  width: 966px;
  margin-top: -2px;
  .mySwiper {
    margin-top: 10px;
    border-radius: 4px;
    .swiper-slide {
      width: 187px;
      height: 232px;
      overflow: hidden;
      background-color: #fff;
      border-radius: 4px;
      .image {
        width: 187px;
        height: 172px;
        object-fit: cover;
        border-radius: 4px 4px 0 0;
      }
      .goods-info {
        padding: 10px 12px;
        font-family: Source Han Sans SC, Source Han Sans SC;
        .store-name {
          color: #333333;
          font-weight: 500;
          font-size: 14px;
          line-height: 21px;
        }
        .template_name {
          color: #666666;
          font-weight: 400;
          font-size: 12px;
          line-height: 18px;
        }
      }
    }

    .swiper-button-prev,
    .swiper-button-next {
      top: 90px;
      width: 40px;
      height: 40px;
      background-color: #33333353;
      border-radius: 50%;
      &::after {
        color: #fff;
        font-size: 14px;
      }
    }
    .swiper-button-prev {
      left: 10px;
      &:after {
        width: 100%;
        height: 100%;
        background: url(../../../../assets/new_imgs/swiper_left.png) no-repeat
          13px center / 10px 20px;
        content: "";
      }
    }
    .swiper-button-next {
      right: 10px;
      &:after {
        width: 100%;
        height: 100%;
        background: url(../../../../assets/new_imgs/swiper_right.png) no-repeat
          16px center / 10px 20px;
        content: "";
      }
    }
  }
}

@primary-color: #f0423f;