.new_home {
  position: relative;
  overflow-x: hidden;

  .container {
    width: 1200px;
  }

  .new_home-banner-main {
    margin-left: 8px;
  }

  .new_home-banner-right {
    margin-left: 7px;
    padding: 0 10px;
    background-color: #fff;
    border-radius: 4px;
    .new_home-login {
      height: 131px;
      padding: 18px 0 21px;
      text-align: center;
      border-bottom: 1px dashed #ccc;
      .zyt_logo {
        width: 36px;
        height: 36px;
        margin-bottom: 8px;
        border-radius: 50%;
      }
      .Hi {
        margin-bottom: 10px;
        color: #333333;
        font-weight: 400;
        font-size: 12px;
      }
      .btn1 {
        width: 39px;
        height: 20px;
        margin-right: 10px;
        color: #fff;
        font-weight: 500;
        font-size: 11px;
        line-height: 20px;
        text-align: center;
        background: var(--main-color);
        border-radius: 2px;
        cursor: pointer;
      }
      .btn2 {
        width: 39px;
        height: 20px;
        font-weight: 400;
        font-size: 11px;
        line-height: 18px;
        text-align: center;
        background: #ffffff;
        border: 1px solid #333333;
        border-radius: 2px;
        cursor: pointer;
      }
    }

    .recommend_goods {
      height: 257px;
      color: #333333;
      font-weight: 500;
      font-size: 12px;
      border-bottom: 1px dashed #ccc;

      .title {
        padding: 8px 0;
      }
      .iconfont {
        margin-right: 6px;
        font-size: 14px;
      }
    }

    .new_shop {
      height: 281px;
      color: #333333;
      font-weight: 500;
      font-size: 12px;

      .title {
        padding: 5px 0;
      }
      .iconfont {
        margin-right: 6px;
        font-size: 18px;
      }
    }
  }

  .new_home_main_bottom {
    display: grid;
    grid-gap: 8px;
    grid-template-columns: 1fr 1fr;
    width: 1200px;
    margin: 8px auto;
  }

  .new_home-others {
    width: 1200px;
    margin: 0 auto;
    .new_home-other {
      .new_home-other-title {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 15px 0;
        .new_home-other-title-line {
          flex: 1;
          border-bottom: 1px solid #e4e4e4;
        }
        & > .title {
          margin: 0 -14px;
          color: #000000;
          font-weight: 500;
          font-size: 22px;
          line-height: 26px;
        }
        & > img {
          width: 12px;
          height: 12px;
          margin: 0 31px;
        }
      }

      .new_home-other-main {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        width: 1200px;
        margin: 0 auto;
        .upload-item {
          margin: 8px;
          img {
            width: 60px;
            height: 60px;
          }
        }
      }
    }
  }
}

@primary-color: #f0423f;