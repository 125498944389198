@baseColor: "#f0423f";
:root {
  --main-color: #f0423f;
  --main-bg-color: #f0423f22;
  --main-bg-color-20: #f0423f44;
  --main-bg-color-50: #f0423f88;
  --main-color-hign: #f0423f;

  // --main-color: #1677ff;
  // --main-bg-color: #1677ff22;
  // --main-bg-color-20: #1677ff44;
  // --main-bg-color-50: #1677ff88;
  // --main-color-hign: #1677ff;
}

.primary-color {
  color: var(--main-color);
}
.primary-color-hover {
  cursor: pointer;
  &:hover {
    color: var(--main-color) !important;
  }
}
.default-color {
  color: #1677ff;
}
.success-color {
  color: #52c41a;
}
.danger-color {
  color: #f0423f;
}
.warning-color {
  color: #faad14;
}

html,
body {
  box-sizing: border-box;
  width: auto;
  height: auto;
  margin: 0px;
  padding: 0px;
  font-family: PingFang SC;
  background-color: #f6f6f6;
  // font-family: Source Han Sans CN-Regular,Source Han Sans CN;
}
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: PingFang SC;
  // font-family: Source Han Sans CN-Regular,Source Han Sans CN;

  //  &::-webkit-scrollbar{
  //     width:8px;
  //     height:6px;
  // }
  // &::-webkit-scrollbar-track{
  //     background: rgb(239, 239, 239);
  //     border-radius:6px;
  // }
  // &::-webkit-scrollbar-thumb{
  //     background: #bfbfbf;
  //     border-radius:6px;
  // }
}
h1,
h2,
h3,
h4,
h5,
h6,
ul,
li,
dl,
dt,
dd,
form,
img,
p {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  list-style-type: none;
  border: none;
}
em,
i,
a {
  font-style: normal;
  text-decoration: none;
}
.no-select {
  -webkit-user-select: none; /* Chrome, Safari, Opera */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
  -webkit-user-drag: none;
}
// 布局
.clearfix:after {
  display: block;
  clear: both;
  visibility: hidden;
  content: "";
}
.container {
  width: 1400px;
  max-width: 1400px !important;
  margin: 0 auto;
}
.container1 {
  width: 1200px;
  margin: 0 auto;
}
.container2 {
  width: 1500px;
  margin: 0 auto;
}
.prela {
  position: relative;
}
.pabso {
  position: absolute;
}
.textC {
  text-align: center;
}
.textL {
  text-align: left;
}
.textR {
  text-align: right;
}
.flex {
  display: flex;
}
.flex1 {
  flex: 1;
}
.flex-b {
  display: flex;
  justify-content: space-between;
}
.flex-r {
  display: flex;
  justify-content: right;
}
.flex-start {
  display: flex;
  align-items: flex-start;
}
.flex-end {
  display: flex;
  align-items: flex-end;
}
.flex-center {
  display: flex;
  align-items: center;
}
.flex-centerY {
  display: flex;
  justify-content: center;
}
.flex-w {
  display: flex;
  flex-wrap: wrap;
}
/* 超出省略号 */
.owt {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: -o-ellipsis-lastline;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
}
.owt2 {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: -o-ellipsis-lastline;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}
.owt3 {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: -o-ellipsis-lastline;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
}
.owt4 {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: -o-ellipsis-lastline;
  text-overflow: ellipsis;
  -webkit-line-clamp: 4;
  line-clamp: 4;
  -webkit-box-orient: vertical;
}
// .required{
//     &::before {
//         display: inline-block;
//         margin-right: 4px;
//         color: #ff4d4f;
//         font-size: 14px;
//         font-family: SimSun, sans-serif;
//         line-height: 1;
//         content: '*';
//     }
// }
// 模块
.line {
  width: 1px;
  height: 12px;
  margin-top: 12px;
  border-left: 1px solid #ccc;
}
.line1 {
  width: 1px;
  height: 70%;
  border-left: 1px solid #fff;
}
.line2 {
  width: 1px;
  height: 30%;
  border-left: 1px solid #ccc;
}
// 操作
// .bgvideo{
//   position: relative;
//   &::before{
//     content: '';
//     position: absolute;
//     bottom: 15px;
//     left: 15px;
//     width: 50px;
//     height: 50px;
//     background: url(../icon/detail1.png) no-repeat 0 0;
//     background-size: 100%;
//     z-index: 90;
//   }
// }
.pointer {
  cursor: pointer;
}
.no-drop {
  cursor: no-drop !important;
}
.hover-red {
  &:hover {
    color: var(--main-color) !important;
  }
}
.red {
  color: #e5272b !important;
}
// tag
.default {
  display: inline-block;
  height: 20px;
  margin-left: 4px;
  padding: 0 6px;
  color: #fff;
  font-size: 12px;
  line-height: 20px;
  text-align: center;
  background-color: #ffa940;
  border-radius: 2px;
  transform: scale(0.86);
}

.active-line {
  position: relative;
  &::before {
    position: absolute;
    bottom: 10px;
    left: 0%;
    width: 100%;
    height: 2px;
    background-color: #e5272b;
    content: "";
  }
}
.active-line1 {
  position: relative;
  &::before {
    position: absolute;
    bottom: 6px;
    left: 0%;
    width: 100%;
    height: 2px;
    background-color: #e5272b;
    transition: all 0.25s linear;
    content: "";
  }
}
.hover-line {
  position: relative;
  &::before {
    position: absolute;
    bottom: 6px;
    left: 50%;
    width: 0;
    height: 2px;
    background-color: #e5272b;
    transition: all 0.25s linear;
    content: "";
  }
  &:hover {
    &::before {
      position: absolute;
      bottom: 6px;
      left: 0%;
      width: 100%;
      height: 2px;
      background-color: #e5272b;
      transition: all 0.25s linear;
      content: "";
    }
  }
}
.hot_yifang {
  position: relative;
  &:after {
    position: absolute;
    top: -13px;
    right: -90px;
    z-index: 9;
    width: 108px;
    height: 35px;
    object-fit: contain;
    color: #fff;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    background: url("../../assets/imgs/yifang.png") no-repeat 0 0;
    background-size: 100%;
    border-radius: 2px;
    content: "";
  }
}
.new {
  position: relative;
  &:after {
    position: absolute;
    top: 0px;
    right: -20px;
    z-index: 9;
    width: 36px;
    height: 16px;
    color: #fff;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    background-image: linear-gradient(to right, #ffb33f, #ff1c55);
    border-radius: 2px;
    transform: scale(0.7);
    content: "New";
    // background-color: #ff1c55;
  }
}
.new1 {
  position: relative;
  &:after {
    position: absolute;
    top: -5px;
    right: 0px;
    z-index: 9;
    width: 36px;
    height: 16px;
    color: #fff;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    background-image: linear-gradient(to right, #ffb33f, #ff1c55);
    border-radius: 8px;
    transform: scale(0.7);
    content: "New";
    // background-color: #ff1c55;
  }
}
.hot {
  position: relative;
  &:after {
    position: absolute;
    top: 0px;
    right: -20px;
    z-index: 99;
    width: 36px;
    height: 16px;
    color: #fff;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    background-image: linear-gradient(to right, #ffb33f, #ff1c55);
    border-radius: 2px;
    transform: scale(0.7);
    content: "Hot";
  }
}
.skip-hot {
  position: relative;
  .skip-hot-icon {
    position: absolute;
    top: -2px;
    right: 2px;
    z-index: 9;
    padding: 0 4px;
    padding: 2px 2px;
    color: #fff;
    font-size: 12px;
    line-height: 1;
    white-space: nowrap;
    text-align: center;
    background-color: var(--main-color);
    border-radius: 6px 6px 6px 6px;
    transform: translateX(100%);
    & > span {
      display: block;
      font-weight: 400;
      transform: scale(0.7);
    }
  }
  @keyframes jump {
    0% {
      top: -12px;
    }
    100% {
      top: -8px;
    }
  }
}
.skip-new {
  position: relative;
  .skip-hot-icon {
    position: absolute;
    top: -2px;
    right: 2px;
    z-index: 9;
    padding: 0 4px;
    padding: 2px 2px;
    color: #fff;
    font-size: 12px;
    line-height: 1;
    white-space: nowrap;
    text-align: center;
    background-color: var(--main-color);
    border-radius: 6px 6px 6px 6px;
    transform: translateX(100%);
    & > span {
      display: block;
      font-weight: 400;
      transform: scale(0.7);
    }
  }
  @keyframes jump {
    0% {
      top: -12px;
    }
    100% {
      top: -8px;
    }
  }
}
.hot1 {
  position: relative;
  &:after {
    position: absolute;
    top: -6px;
    right: -14px;
    z-index: 99;
    width: 32px;
    height: 16px;
    color: #fff;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    background-image: linear-gradient(to right, #ffb33f, #ff1c55);
    border-radius: 16px;
    transform: scale(0.7);
    content: "New";
    // background-color: #ff1c55;
  }
}
.hot2 {
  position: relative;
  &:after {
    position: absolute;
    top: -12px;
    right: -20px;
    width: 40px;
    height: 20px;
    color: #fff;
    font-size: 12px;
    line-height: 20px;
    text-align: center;
    background-image: linear-gradient(to right, #ffb33f, #ff1c55);
    border-radius: 2px;
    transform: scale(0.7);
    content: "New";
    // background-color: #ff1c55;
  }
}
.hot3 {
  position: relative;
  &:after {
    position: absolute;
    top: -6px;
    right: -14px;
    z-index: 99;
    width: 32px;
    height: 16px;
    color: #fff;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    background-image: linear-gradient(to right, #ffb33f, #ff1c55);
    border-radius: 16px;
    transform: scale(0.7);
    content: "Hot";
    // background-color: #ff1c55;
  }
}
.disney {
  position: relative;
  &:after {
    position: absolute;
    top: 4px;
    right: -14px;
    z-index: 9;
    width: 16px;
    height: 16px;
    // border-radius: 50%;
    // background: url(../imgs/disney_star1.jpg) no-repeat 0 0/100%;
    background: url(../icon/disney.png) no-repeat center center/90%;
    content: "";
    // background-color: #ff1c55;
  }
}
// video
.operators {
  opacity: 0;
}
// antdStyle
.down-icon {
  margin-left: 2px;
  color: rgb(182, 182, 182);
  font-size: 12px;
}
.ant-popover {
  // top: 96px !important;
  z-index: 9999999999;
}
.ant-tabs-ink-bar {
  background-color: #e5272b;
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #e5272b;
}
.ant-tabs-tab:hover {
  color: #e5272be6;
}
.ant-tabs-tab-btn:active {
  color: #e5272be6;
}
.ant-breadcrumb a:hover {
  color: #e5272b;
}
.ant-input:focus,
.ant-input-focused {
  box-shadow: 0 0 0 0 #000;
}
.ant-picker:focus,
.ant-picker-focused {
  box-shadow: 0 0 0 0 #000;
}
.ant-select:focus,
.ant-select-focused {
  box-shadow: 0 0 0 0 #000;
}
.centerImg {
  width: 100% !important;
  height: 100% !important;
  object-fit: cover !important;
}
.lazyload-wrapper {
  .ant-image-error {
    background-color: #fafafa;
    .centerImg {
      width: 100% !important;
      height: 100% !important;
      object-fit: contain;
    }
  }
  .ant-image-img {
    object-fit: contain;
    background-color: #fafafa;
  }
}

.ant-message-notice-content {
  box-shadow: 0 3px 6px -4px rgba(194, 78, 78, 0.2),
    0 6px 16px 0 rgba(162, 74, 74, 0.16), 0 9px 28px 8px rgba(170, 92, 92, 0.1) !important;
}
.custom-class {
  margin-top: -100px;
  .ant-message-notice-content {
    height: 120px;
    padding: 29px 56px;
    color: #fff;
    background-color: #00000090;
  }
  .ant-spin-dot-item {
    background-color: #fff;
  }
}
.ant-badge-multiple-words {
  padding: 0 4px;
}
// swiperStyle
.swiper-container {
  &:hover {
    .swiper-button-prev,
    .swiper-button-next {
      background-color: #33333353;
      opacity: 1;
      &:hover {
        background-color: #33333388;
      }
    }
  }
}
.swiper-button-prev,
.swiper-button-next {
  width: 40px;
  height: 67px;
  background-color: #333;
  border-radius: 10px;
  opacity: 0;
  &::after {
    color: #fff;
    font-size: 16px;
  }
}
.swiper-button-prev {
  left: 0;
  &:after {
    width: 100%;
    height: 100%;
    background: url(../new_imgs/swiper_left.png) no-repeat center / 16px 27px;
    content: "";
  }
}
.swiper-button-next {
  right: 0;
  &:after {
    width: 100%;
    height: 100%;
    background: url(../new_imgs/swiper_right.png) no-repeat center / 16px 27px;
    content: "";
  }
}
.swiper-pagination-bullet-active {
  background-color: var(--main-color);
}
.loading {
  display: inline-block;
  transition: margin-left 0.3s cubic-bezier(0.65, 0.05, 0.36, 1);
  animation: loadingCircle 1s linear infinite;
}
@keyframes loadingCircle {
  0% {
    -webkit-transform: rotate(0deg);
  }
  25% {
    -webkit-transform: rotate(90deg);
  }
  50% {
    -webkit-transform: rotate(180deg);
  }
  75% {
    -webkit-transform: rotate(270deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
// 边距
.ml2 {
  margin-left: 2px;
}
.ml3 {
  margin-left: 3px;
}
.ml4 {
  margin-left: 4px;
}
.ml6 {
  margin-left: 6px;
}
.ml8 {
  margin-left: 8px;
}
.ml10 {
  margin-left: 10px !important;
}
.ml12 {
  margin-left: 12px !important;
}
.ml20 {
  margin-left: 20px;
}

.mr1 {
  margin-right: 1px;
}
.mr2 {
  margin-right: 2px;
}
.mr3 {
  margin-right: 3px;
}
.mr4 {
  margin-right: 4px;
}
.mr6 {
  margin-right: 6px;
}
.mr8 {
  margin-right: 8px;
}
.mr10 {
  margin-right: 10px;
}
.mr12 {
  margin-right: 12px;
}
.mr20 {
  margin-right: 20px;
}
.mr30 {
  margin-right: 30px;
}

.mtop2 {
  margin-top: 2px;
}
.mtop4 {
  margin-top: 4px;
}
.mtop6 {
  margin-top: 6px;
}
.mtop7 {
  margin-top: 7px;
}
.mtop8 {
  margin-top: 8px;
}
.mtop10 {
  margin-top: 10px !important;
}
.mtop12 {
  margin-top: 12px !important;
}
.mtop20 {
  margin-top: 20px !important;
}

.mbottom6 {
  margin-bottom: 6px;
}
.mbottom8 {
  margin-bottom: 8px;
}
.mbottom10 {
  margin-bottom: 10px !important;
}
.mbottom12 {
  margin-bottom: 12px;
}
.mbottom20 {
  margin-bottom: 20px;
}
.mbottom30 {
  margin-bottom: 30px;
}
// 颜色
._448ef7 {
  color: #448ef7 !important;
}
._ff7875 {
  color: #ff7875;
}
._409eff {
  color: #409eff !important;
}
._ffec3d {
  color: #ffec3d !important;
}
._40a9ff {
  color: #40a9ff !important;
}
._357aff {
  color: #357aff;
}
._e5272b {
  color: #e5272b !important;
}
._aaa {
  color: #aaa !important;
}
._ccc {
  color: #ccc !important;
}
._111 {
  color: #111 !important;
}
._333 {
  color: #333 !important;
}
._ddd {
  color: #ddd;
}
._f5f5f5 {
  color: #f5f5f5;
}
._fff {
  color: #fff !important;
}
._666 {
  color: #666;
}
._999 {
  color: #999 !important;
}
._ff2d4f {
  color: #ff2d4f;
}
._c27c55 {
  color: #c27c55;
}
._ff5d0c {
  color: #ff5d0c;
}
._7C48E6 {
  color: #7c48e6;
}
._005aa0 {
  color: #005aa0;
}
._8f8f8f {
  color: #8f8f8f;
}
._636363 {
  color: #636363;
}
._0075ff {
  color: #0075ff;
}
._3e6bc4 {
  color: #3e6bc4;
}
._000D9 {
  color: #000000d9;
  &:hover {
    color: #000000d9;
  }
}
// 字号
.fw700 {
  font-weight: 700;
}
.fw400 {
  font-weight: 400;
}
.fz10 {
  font-size: 10px !important;
}
.fz11 {
  font-size: 11px !important;
}
.fz12 {
  font-size: 12px !important;
}
.fz13 {
  font-size: 13px !important;
}
.fz14 {
  font-size: 14px !important;
}
.fz15 {
  font-size: 15px !important;
}
.fz16 {
  font-size: 16px;
}
.fz17 {
  font-size: 17px;
}
.fz18 {
  font-size: 18px;
}
.fz20 {
  font-size: 20px;
}
.fz22 {
  font-size: 22px;
}
.fz24 {
  font-size: 24px;
}
.fz26 {
  font-size: 26px;
}
.fz32 {
  font-size: 32px;
}
.fz40 {
  font-size: 40px;
}
// other
.lssue {
  display: inline-block;
  margin-right: 3px;
  padding: 0 4px;
  color: #f0423f;
  font-size: 12px;
  vertical-align: top;
  border: 1px solid #f0423f;
  border-radius: 4px;
}
.lssue1 {
  display: inline-block;
  margin-right: 3px;
  padding: 0 4px;
  color: #2998ff;
  font-size: 12px;
  vertical-align: top;
  border: 1px solid #2998ff;
  border-radius: 4px;
}
.lssue2 {
  display: inline-block;
  margin-right: 3px;
  padding: 0 4px;
  color: #52c41a;
  font-size: 12px;
  vertical-align: top;
  border: 1px solid #52c41a;
  border-radius: 4px;
}

.uploadModal {
  .uploadLink {
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 10px;
    overflow: hidden;
    border-bottom: 1px solid #dcdcdc;
    .dif-lit-pt {
      position: relative;
      float: left;
      width: 20%;
      margin-top: 12px;
      margin-bottom: 5px;
      text-align: center;
      i {
        display: block;
        width: 100%;
      }
      a {
        color: #848484 !important;
      }
      p {
        margin-top: 6px;
      }
    }
  }
  .mess {
    margin-top: 10px;
    font-size: 13px;
    & > p {
      color: #2da2fb;
    }
  }
}

// 平台公告
.article-fix-modal {
  position: fixed;
  right: 20px;
  bottom: 20px;
  z-index: 9999;
  width: 398px;
  min-height: 300px;
  padding: 0 24px;
  background: url("../new_home/article_bg.png") no-repeat 0 0 / 100% auto;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.04);
  .modal-close {
    position: absolute;
    top: -40px;
    right: 0;
    width: 30px;
    height: 30px;
    color: #999;
    font-size: 16px;
    line-height: 30px;
    text-align: center;
    background-color: #fff;
    border-radius: 50%;
    box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.04);
    cursor: pointer;
  }
  .modal-title {
    height: 61px;
    padding-top: 8px;
    border-bottom: 1px solid #efefef;
    .title {
      color: #333333;
      font-weight: bold;
      font-size: 18px;
      background: url("../new_home/artile_title_bg.png") no-repeat left 20px /
        100% auto;
    }
    .more {
      color: #999999;
      font-weight: 400;
      font-size: 12px;
      &:hover {
        color: var(--main-color);
      }
    }
  }
  .artile-main {
    padding: 24px 0 35px;
    .article-title {
      margin-bottom: 18px;
      color: #333333;
      font-weight: bold;
      font-size: 16px;
      line-height: 30px;
      text-align: center;
    }
    .article-introduction {
      margin-bottom: 32px;
      color: #666666;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      text-indent: 2em;
    }
    .left {
      width: 124px;
      height: 40px;
      margin-right: 52px;
      color: #999999;
      font-weight: 400;
      font-size: 16px;
      line-height: 40px;
      text-align: center;
      background: #f7f7f7;
      border-radius: 35px;
      cursor: pointer;
    }
    .right {
      color: #fff;
      background: #f0423f;
      margin-right: 0;
    }
  }
}

@primary-color: #f0423f;