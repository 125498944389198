// 活动
.wealth-home {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1001;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background: #00000088;
  // 转盘
  .wealth-home-main {
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
    zoom: 0.9;
    .wealth-home-main-colse {
      position: absolute;
      bottom: -40px;
      left: 50%;
      transform: translate(-50%);
      cursor: pointer;
      & > img {
        width: 38px;
        height: 38px;
      }
    }
  }
  // title
  .wealth-raffle-turntable-title {
    width: 743px;
    height: 170px;
    margin-bottom: -120px;
    background: url("/assets/wealth/wealth_raffer_title.png") no-repeat center /
      100%;
  }
  // bottom
  .wealth-raffle-turntable-bottom {
    display: flex;
    align-items: flex-end;
    justify-content: space-around;
    width: 521px;
    height: 204px;
    margin: 0 auto;
    margin-top: -160px;
    padding: 0 24px 28px;
    background: url("/assets/wealth/wealth_raffer_bottom.png") no-repeat center /
      100%;
    .wealth-raffle-turntable-bottom-btn {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 189px;
      height: 58px;
      padding-top: 4px;
      color: #7c3e00;
      font-weight: bold;
      font-size: 21px;
      line-height: 58px;
      background: url("/assets/wealth/wealth_raffer_bottom_icon.png") no-repeat
        center / 100%;
      cursor: pointer;
      img {
        width: 36px;
        height: 36px;
      }
    }
  }
  .wealth-raffle-turntable-box {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 680px;
    height: 680px;
    margin: 0 auto;
    background: url("/assets/wealth/wealth_raffer_bg.png") no-repeat center /
      100%;
  }
  .wealth-raffle-turntable {
    position: relative;
    width: 634px;
    height: 634px;
    padding: 12px;
    background: linear-gradient(180deg, #ff6a06 0%, #ff5b07 100%);
    border: 1px solid #ffa263;
    border-radius: 50%;
    box-shadow: inset 0 0 10px 1px #cc3600;
    zoom: 0.777;
    .turntable-box {
      position: relative;
      width: 100%;
      height: 100%;
      padding: 28px;
      background: linear-gradient(135deg, #ffe887 0%, #fe7a2b 100%);
      border-radius: 50%;
      .turntable-points {
        .turntable-point {
          position: absolute;
          width: 12px;
          height: 12px;
          background-color: #fff;
          border-radius: 50%;
          box-shadow: 0 0 6px 0 #f3dda888;
        }
        .turntable-point1 {
          top: 8px;
          left: 50%;
          transform: translateX(-50%);
        }
        .turntable-point2 {
          bottom: 8px;
          left: 50%;
          transform: translateX(-50%);
        }
        .turntable-point3 {
          top: 12.5%;
          left: 12.5%;
          transform: translate(140%, 140%);
        }
        .turntable-point4 {
          bottom: 12.5%;
          left: 12.5%;
          transform: translate(140%, -140%);
        }
        .turntable-point5 {
          top: 12.5%;
          right: 12.5%;
          transform: translate(-140%, 140%);
        }
        .turntable-point6 {
          right: 12.5%;
          bottom: 12.5%;
          transform: translate(-140%, -140%);
        }
        .turntable-point7 {
          top: 50%;
          left: 8px;
          transform: translateY(-50%);
        }
        .turntable-point8 {
          top: 50%;
          right: 8px;
          transform: translateY(-50%);
        }
      }
      .turntable-items-box {
        width: 100%;
        height: 100%;
        padding: 18px;
        background: linear-gradient(135deg, #feca2e 0%, #f79307 100%);
        border-radius: 50%;
        box-shadow: inset -1px -2px 1px 0 #fef0e1;
      }
      .turntable-items {
        position: relative;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        box-shadow: 0 0 1px 1px #fbf1e7;

        .turntable-item {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          padding-top: 32px;
          color: #d96a37;
          font-weight: 700;
          font-size: 22px;
          text-align: center;
          background: #fefdfe;
          border-radius: 100%;
          box-shadow: inset 0 0 0 6px #feeed9;

          &:nth-child(2n) {
            background-color: #ffeac8;
            box-shadow: inset 0 0 0 6px #fedd9e;
          }
        }
      }
    }
    .turntable-start {
      transition: all 4.8s cubic-bezier(0.53, 0.88, 0.7, 1.03);
    }
    .turntable-btn {
      position: absolute;
      top: 50%;
      left: 50%;
      z-index: 99;
      width: 218px;
      height: 218px;
      padding: 80px 0 0;
      color: #fff;
      text-align: center;
      background: url("/assets/wealth/wealth_raffle_turntable_icon.png")
        no-repeat center / 100%;
      transform: translate(-50%, -50%);
      cursor: pointer;
      .turntable-text {
        font-weight: 700;
        font-size: 50px;
        line-height: 50px;
      }
      .count-text {
        margin-top: 2px;
        color: #ffeac8;
        font-weight: 400;
        font-size: 16px;
      }
      .count {
        position: absolute;
        top: 38px;
        right: 40px;
        width: 32px;
        height: 32px;
        font-size: 20px;
        line-height: 28px;
        text-align: center;
        background-color: #eb492a;
        border: 2px solid #fff;
        border-radius: 50;
        border-radius: 50%;
      }
    }
  }

  // 中奖特效
  .wealth-red-box {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1002;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    background: #00000088;
    .wealth-red-main {
      position: absolute;
      top: 45%;
      left: 50%;
      width: 255px;
      height: 208.81px;
      padding-top: 30px;
      padding-left: 9px;
      text-align: center;
      background: url(/assets/wealth/wealth_raffer_red.png) no-repeat center /
        100%;
      transform: translate(-50%, -50%);
      animation: enlarge 1s ease-in-out infinite, narrow 1s ease-in-out infinite;
      animation-delay: 0s, 2s;
      animation-iteration-count: 1;
      .wealth-red-text {
        color: #ff5100;
        font-weight: 800;
        font-size: 30px;
      }

      @keyframes enlarge {
        0% {
          transform: translate(-50%, -50%) scale(0);
        }
        100% {
          transform: translate(-50%, -50%) scale(1);
        }
      }
      @keyframes narrow {
        0% {
          transform: translate(-50%, -50%) scale(1);
        }
        100% {
          transform: translate(-9%, 90%) scale(0);
        }
      }
    }
  }
}

@primary-color: #f0423f;