.new_home-big-banner {
  position: relative;
  width: 771px;
  height: 429px;
  border-radius: 4px;
  overflow: hidden;
  .mySwiper {
    height: 429px;
    .swiper-slide {
      width: 100% !important;
      height: 429px;
      overflow: hidden;
      .mySwiper-item {
        width: 100%;
        height: 100%;
        cursor: pointer;
      }
      .box-items {
        display: grid;
        grid-template-columns: 1fr 1fr;
        height: 100%;

        .box-item {
          min-height: 0;
          overflow: hidden;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

        .boxItem1 {
          grid-row-start: 1;
          grid-row-end: 3;
        }

        .boxItem2 {
          grid-row-start: 1;
          grid-row-end: 4;
          grid-column-start: 2;
          grid-column-end: 4;
        }
      }
    }
    .ant-image {
      display: flex;
      justify-content: center;
      width: 100%;
      height: 100%;
      .image {
        object-fit: cover;
      }
    }
    .swiper-button-prev,
    .swiper-button-next {
      width: 40px;
      height: 40px;
      background-color: #333;
      border-radius: 50%;
      opacity: 0;
      &::after {
        color: #fff;
        font-size: 14px;
      }
    }
    .swiper-button-prev {
      left: 10px;
      &:after {
        width: 100%;
        height: 100%;
        background: url(../../../../assets/new_imgs/swiper_left.png) no-repeat
          13px center / 10px 20px;
        content: "";
      }
    }
    .swiper-button-next {
      right: 10px;
      &:after {
        width: 100%;
        height: 100%;
        background: url(../../../../assets/new_imgs/swiper_right.png) no-repeat
          16px center / 10px 20px;
        content: "";
      }
    }
  }

  .spots {
    position: absolute;
    bottom: 0;
    left: 50%;
    z-index: 3;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
    height: 32px;
    color: #cfcfcf;
    text-align: center;
    text-align: center;
    background-color: #00000088;
    transform: translateX(-50%);
    transition: all 0.25s linear;
    .spot {
      width: 100px;
      height: 24px;
      color: #fff;
      color: #ffffff;
      font-weight: 500;
      font-size: 13px;
      line-height: 24px;
      text-align: center;
      border: 1px solid rgba(255, 255, 255, 0.6);
      border-radius: 6px;
      cursor: pointer;
      margin: 0 4px;
    }
    .spot_active {
      color: var(--main-color);
      background-color: #fff;
    }
  }
}

@primary-color: #f0423f;