.photo-modal {
  .ant-modal-close {
    top: 6px;
    right: 0px;
  }
  .ant-modal-close-x {
    width: 32px;
    height: 32px;
    line-height: 32px;
    border-radius: 8px;
    &:hover {
      background-color: #e8ebef;
    }
  }

  .ant-modal-body {
    padding-top: 12px;
  }
}
.ykt-modal {
  position: relative;
  height: calc(100vh - 100px);
  #ykt-editor {
    height: 100%;
  }

  .box-modal1 {
    position: absolute;
    top: 4px;
    left: -10px;
    width: calc(100% - 116px);
    height: 100%;
    padding-left: 136px;
    color: #fff;
    font-size: 24px;
    line-height: 400px;
    text-align: center;
    background-color: #000a;
    border-radius: 12px 0 0 12px;
  }

  .box-modal2 {
    position: absolute;
    top: 56px;
    right: -10px;
    width: 136px;
    height: calc(100% - 52px);
    background-color: #000a;
    border-radius: 0 0 12px 0;
  }

  .box-spinning {
    position: absolute;
    top: 0;
    right: -10px;
    left: -10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding-right: 10px;

    background-color: #fff;
    border-radius: 0 0 12px 0;
    img {
      width: 60px;
      height: 60px;
      border-radius: 12px;
    }
    span {
      margin-right: 10px;
      margin-left: 28px;
      font-weight: 700;
      font-size: 32px;
    }
    svg {
      width: 92px;
      height: 92px;
    }
    i {
      margin-left: -4px;
      font-size: 28px;
    }

    .ant-spin-dot-item {
      background-color: #1677ff;
    }
  }

  .upload-box-spinning {
    position: absolute;
    top: 0;
    right: -10px;
    left: -10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding-right: 10px;

    background-color: #fff;
    border-radius: 0 0 12px 0;

    .flex-centerY {
      img {
        width: 52px;
        height: 52px;
        border-radius: 12px;
      }
      span {
        margin-right: 8px;
        margin-left: 26px;
        font-weight: 700;
        font-size: 32px;
      }
      svg {
        width: 88px;
        height: 88px;
      }
      i {
        margin-left: -4px;
        font-size: 28px;
      }

      .ant-spin-dot-item {
        background-color: #1677ff;
      }
    }
    .ant-upload-drag {
      width: 758px;
      max-height: 408px;
      background: #fffafa;
      border: 1px dashed #f0423f;
      border-radius: 32px;
      &:hover {
        background: #f0423f20;
      }
      .ant-upload-drag-container {
        padding: 60px 0;
        text-align: center;
        .upload-icon {
          width: 70px;
          margin-bottom: 24px;
        }
        .upload-text {
          color: #999999;
          font-weight: 400;
          font-size: 18px;
          line-height: 30px;
        }
        .upload-btn {
          display: inline-flex;
          align-items: center;
          justify-content: center;
          width: 300px;
          height: 56px;
          margin-top: 46px;
          color: #ffffff;
          font-weight: bold;
          font-size: 18px;
          background: #f0423f;
          border-radius: 35px;
          box-shadow: 0 10px 16px 1px #fac5c5;
          img {
            width: 20px;
            margin-right: 16px;
          }
        }
      }
    }
  }

  .box-logo {
    position: absolute;
    top: 0px;
    left: -6px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 130px;
    height: 64px;
    padding-right: 6px;
    background-color: #fff;
    border-bottom: 1px solid #eef0f3;
    border-radius: 0 0 12px 0;
    img {
      width: 26px;
      height: 26px;
      border-radius: 6px;
    }
    span {
      margin-right: -4px;
      margin-left: 4px;
      font-size: 20px;
    }
    svg {
      width: 40px;
      height: 40px;
    }
    i {
      margin-left: -2px;
      font-weight: 700;
      font-size: 16px;
    }
  }

  .box-title {
    position: absolute;
    top: 16px;
    left: 770px;
    width: 160px;
    height: 30px;
    background-color: transparent;
    cursor: pointer;
  }

  .box-footer {
    position: absolute;
    right: 16px;
    bottom: 16px;
    width: 36px;
    height: 36px;
    background-color: transparent;
    border-radius: 6px;
    cursor: pointer;
  }

  .box-wx {
    position: absolute;
    bottom: 0px;
    left: 82px;
    width: 300px;
    height: 80px;
    background-color: #f008;
  }
}

@primary-color: #f0423f;